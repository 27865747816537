/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useEffect, useState } from 'react';

import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';

import { FieldValidation } from '@rjsf/utils';

import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import axios from 'axios';

import { parseAllDocuments } from 'yaml';

import { GBTechRepositoryPicker } from '../GBTechRepositoryPicker';
import validate from './validate';
import { useCatalogInfoLoader } from './hooks/useCatalogInfoLoader';
import EnvironmentAlert from './components/environmentAlert/environmentAlert';
import { UseCustomFieldDomainFacade } from '../../../../facade/useCustomFieldDomainFacade';

let canBeNextStep = true;
const uiOptions = 'ui:options';
class RepoPickerOptions {
  constructor() {
    this[uiOptions] = {
      org: null,
      allowNewValue: false
    };
  }

  title?: string;
  description?: string;
  [uiOptions]: { [index: string]: any };
}

class CatalogInfo {
  repo: any = {};
  repoOwner = '';
  repoName = '';
  repoDefaultBranch = '';
  component = '';
  componentDescription = '';
  tags: string[] = [];
  team = '';
  lifecycle = 'experimental';
  domain = '';
  system = '';
  hasArgocd = false;
  argocdAppSelector = '';
  argocdProxyUrl = '';
  hasNewRelic = false;
  newRelicEntityName = '';
  environmentSetName = '';
  hasSonar = false;
  sonarqubeOrgProjectKey = '';
  type = '';
}

function getCatalogInfoFomFile(fileContent: string): CatalogInfo {
  const documentsArray = parseAllDocuments(fileContent);
  const jsonComponentArray = documentsArray.map((doc) => doc.toJSON());
  const firstComponent = jsonComponentArray.find((doc) => doc.kind === 'Component');
  const catalogInfoFile = firstComponent;

  if (!firstComponent) {
    throw new Error('catalog-info.yaml deve conter um Component');
  }

  const catalogInfo = {
    component: catalogInfoFile.metadata.name || undefined,
    componentDescription: catalogInfoFile.metadata.description || undefined,
    tags: catalogInfoFile.metadata.tags || [],
    team: catalogInfoFile.spec.owner || undefined,
    lifecycle: catalogInfoFile.spec.lifecycle || undefined,
    system: catalogInfoFile.spec.system || undefined,
    domain: catalogInfoFile.spec.domain || undefined,
    environmentSetName: catalogInfoFile.spec.environmentSet || undefined,
    type: catalogInfoFile.spec.type || undefined
  } as CatalogInfo;

  if (catalogInfoFile.metadata?.annotations) {
    catalogInfo.argocdAppSelector =
      catalogInfoFile.metadata.annotations['argocd/app-selector'] || undefined;
    catalogInfo.argocdProxyUrl =
      catalogInfoFile.metadata.annotations['argocd/proxy-url'] || undefined;
    catalogInfo.newRelicEntityName =
      catalogInfoFile.metadata.annotations['newrelic/entity-name'] || undefined;
    catalogInfo.sonarqubeOrgProjectKey =
      catalogInfoFile.metadata.annotations['sonarqube.org/project-key'] || undefined;
  }

  catalogInfo.hasNewRelic = catalogInfo.newRelicEntityName?.length > 0;
  catalogInfo.hasArgocd = catalogInfo.argocdAppSelector?.length > 0;
  catalogInfo.hasSonar = catalogInfo.sonarqubeOrgProjectKey?.length > 0;

  return catalogInfo;
}

async function getCatalogInfoContent(
  catalogInfo: CatalogInfo,
  repo: any,
  branch: string,
  token: string
) {
  try {
    const content = (
      await axios.get(
        `https://api.github.com/repos/${repo.owner.login}/${repo.name}/contents/catalog-info.yaml?ref=${branch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/vnd.github.v3.raw',
            'Content-Type': 'application/json'
          }
        }
      )
    ).data;

    return {
      ...catalogInfo,
      ...getCatalogInfoFomFile(content),
      repo,
      repoDefaultBranch: branch,
      repoName: repo.name,
      repoOwner: repo.owner.name
    };
  } catch (e) {
    if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
      return {
        ...new CatalogInfo(),
        repo,
        repoDefaultBranch: branch,
        repoName: repo.name,
        repoOwner: repo.owner.name
      };
    }
    throw e;
  }
}

function isObjectEmpty(object: any) {
  return !object || !Object.keys(object).length;
}

export const GBTechCatalogInfoLoader = (param: FieldExtensionComponentProps<any>) => {
  const { onChange, uiSchema, formData } = param;

  let repoPickerOptions = new RepoPickerOptions();
  if (uiSchema[uiOptions]?.repoPicker) {
    repoPickerOptions = uiSchema[uiOptions]?.repoPicker as RepoPickerOptions;
  }
  const validateEnvironment = uiSchema[uiOptions]?.validateEnvironment as boolean;
  const branch = uiSchema[uiOptions]?.branch as string;
  const githubAuth = useApi(githubAuthApiRef);
  const [githubToken, setGithubToken] = useState(String);
  const [loading, setLoading] = useState<boolean>(false);
  const [repo, setRepo] = useState<any>({});
  const [catalogInfo, setCatalogInfo] = useState<CatalogInfo>(
    formData ? (formData as CatalogInfo) : { ...new CatalogInfo(), repo: uiSchema[uiOptions]?.repo }
  );
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const { environment, hasEnvironmentSet, loadEnvironmentByDomain } = useCatalogInfoLoader();
  useEffect(() => {
    async function fetchCatalogInfo() {
      if (!githubToken || !repo || catalogInfo.repo?.nameWithOwner === repo?.nameWithOwner) {
        return;
      }

      let branchToFetch = branch;
      if (!branchToFetch) {
        branchToFetch = repo.defaultBranchRef?.name;
      }
      if (!branchToFetch) {
        return;
      }
      canBeNextStep = true;
      try {
        setLoading(true);
        const catalogInfoData = await getCatalogInfoContent(
          catalogInfo,
          repo,
          branchToFetch,
          githubToken
        );

        setCatalogInfo(catalogInfoData);
        UseCustomFieldDomainFacade.setDomain(catalogInfoData.domain);
      } finally {
        setLoading(false);
      }
    }
    fetchCatalogInfo();
  }, [githubToken, catalogInfo, repo, branch]);

  useEffect(() => {
    const fetchGithubToken = async () => {
      const accessToken = await githubAuth.getAccessToken(['repo']);

      setGithubToken(accessToken);
    };

    if (!githubToken) {
      fetchGithubToken();
    }

    if (isObjectEmpty(repo) && isObjectEmpty(formData)) {
      onChange(null);
    }
  }, [formData, githubAuth, githubToken, onChange, repo]);

  useEffect(() => {
    onChange(catalogInfo);
    if (!!Object.keys(catalogInfo ?? {}).length && !catalogInfo.environmentSetName) {
      loadEnvironmentByDomain(catalogInfo.domain);
    }
    resetStateAlert();
  }, [catalogInfo, onChange]);

  useEffect(() => {
    if (!validateEnvironment) return;
    if (!environment && typeof hasEnvironmentSet === 'undefined') {
      return;
    }
    if (environment && hasEnvironmentSet) {
      return setCatalogInfo((catalog) => ({
        ...catalog,
        environmentSetName: environment.metadata.name
      }));
    }
    setShowAlert(true);
    canBeNextStep = false;
  }, [environment, hasEnvironmentSet]);

  const resetStateAlert = () => {
    if (showAlert && canBeNextStep) setShowAlert(false);
  };

  return (
    <React.Fragment>
      <GBTechRepositoryPicker
        {...{
          ...param,
          ...{
            idSchema: param.idSchema,
            onBlur: param.onBlur,
            onFocus: param.onFocus,
            disabled: param.disabled,
            readonly: param.readonly,
            registry: param.registry,
            name: param.name,
            onChange: (value) => {
              if (value?.nameWithOwner === repo?.nameWithOwner) {
                return;
              }
              setRepo(value);
            },
            schema: {
              title: repoPickerOptions.title,
              description: repoPickerOptions.description
            },
            uiSchema: repoPickerOptions,
            formData: catalogInfo.repo,
            rawErrors: new Array<string>()
          }
        }}
      />

      {loading ? (
        <Alert
          variant="outlined"
          severity="info"
          data-testid="loading"
          icon={<CircularProgress color="inherit" size={20} />}
        >
          Loading catalog-info.yaml
        </Alert>
      ) : null}
      <EnvironmentAlert show={showAlert} />
    </React.Fragment>
  );
};

export const GBTechCatalogInfoLoaderFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechCatalogInfoLoader,
    name: 'GBTechCatalogInfoLoader',
    validation: (data: any, fieldValidation: FieldValidation, context: any) => {
      if (!data) {
        return fieldValidation.addError('Selecione um repositório válido');
      }

      let mustHaveCatalogInfo = context.uiSchema[uiOptions]?.mustHaveCatalogInfo;
      if (typeof mustHaveCatalogInfo === 'undefined') {
        mustHaveCatalogInfo = true;
      }
      if (mustHaveCatalogInfo) {
        validate(data as CatalogInfo).forEach((e) => fieldValidation.addError(e));
      }
      if (!canBeNextStep) {
        fieldValidation.addError('');
      }
    }
  })
);
