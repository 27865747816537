import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';

import { useStyles } from './styles';
import type { RenderModalProps } from './types';

export const RenderModal = ({
  titleLink,
  onClick,
  children
}: React.PropsWithChildren<RenderModalProps>) => {
  const S = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onClick?.();
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Link onClick={handleOpen} className={S.showModal}>
        {titleLink}
        <OpenInFullIcon className={S.icon} />
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="checks-card-modal-title"
        aria-describedby="checks-card-modal-description"
      >
        <Box className={S.contDetails}>{children}</Box>
      </Modal>
    </div>
  );
};
