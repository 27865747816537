import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles((theme) => ({
  contDetails: {
    marginRight: '30px'
  },
  titleProperty: {
    fontSize: '10px',
    margin: theme.spacing(1, 0, 1, 0)
  },
  resultProperty: {
    marginBottom: '5px',
    fontWeight: 'bold',
    marginRight: '5px'
  },
  listRunner: {
    fontWeight: 'bold',
    margin: '0px 5px 0px 0px',
    backgroundColor: theme.palette.background.paper,
    padding: '5px',
    borderRadius: '6px'
  },
  contActions: {
    textAlign: 'left'
  },
  buttonAction: {
    margin: '0px 10px 5px 0px',
    fontSize: '12px'
  },
  iconsButton: {
    marginRight: '10px',
    fontSize: '20px',
    padding: '0px'
  },
  buttonIcon: {
    padding: '0px',
    marginRight: '10px'
  },
  itemDetails: {
    marginRight: '30px'
  },
  link: {
    color: theme.palette.primary.main
  },
  list: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    fontSize: '10px'
  },
  public: {
    backgroundColor: alpha(theme.palette.status.ok, 0.6),
    border: `1px solid ${theme.palette.status.ok}`,
    padding: '0px 8px',
    borderRadius: '25px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    margin: '14px 5px 5px 0',
    justifyContent: 'center'
  },
  listSquads: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
