import React, { ComponentType, useState } from 'react';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import type { ReviewStepProps } from '@backstage/plugin-scaffolder-react';
import { ReviewState } from '@backstage/plugin-scaffolder-react/alpha';
import CostsSummary from '../../CostsSummary';
import { useStyles } from './styles';
import { UseCustomFieldDomainFacade } from '../../../../facade/useCustomFieldDomainFacade';
import { useAnalytics } from '@backstage/core-plugin-api';
import { handleFieldEvents, captureEvent } from './ReviewEventsAnalytics';

export const ReviewStepComponent: ComponentType<ReviewStepProps> = ({
  formData,
  steps,
  handleBack,
  handleCreate
}) => {
  const S = useStyles();
  const [checked, setChecked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const analytics = useAnalytics();

  const isCatalogInfo = !!Object.keys(formData?.catalogInfo ?? {}).length;
  if (isCatalogInfo) {
    (formData.catalogInfo as unknown as any).domain = UseCustomFieldDomainFacade.getDomain();
  }

  const schemas = steps.map((s) => ({ ...s, title: 'satisfy title' }));

  const handleClick = () => {
    setIsClicked(true);
    handleCreate();
    setTimeout(() => setIsClicked(false), 2000);
    handleFieldEvents(analytics, formData);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    captureEvent(analytics, `checkbox:select:${formData?.repositoryName}`);
  };

  const handleBacks = () => {
    handleBack();
    captureEvent(analytics, `back:click:${formData?.repositoryName}`);
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={formData?.services ? 8 : 12} className={S.ReviewContainer}>
          <ReviewState formState={formData} schemas={schemas} />
        </Grid>
        <Grid item xs={4} hidden={!formData?.services}>
          <CostsSummary formData={formData} />
          <FormControlLabel
            control={
              <Checkbox name="acceptCosts" color="primary" onChange={handleCheckboxChange} />
            }
            label={<Typography>I agree that the costs will be allocated to my product</Typography>}
          />
        </Grid>
      </Grid>
      <div className={S.Footer}>
        <Button onClick={handleBacks} className={S.BackButton}>
          Back
        </Button>
        <Tooltip
          title={`${
            !checked
              ? 'Para prosseguir, concorde com os termos de custo marcando a checkbox de custos estimados'
              : ''
          }`}
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              disabled={(!checked && formData?.services !== undefined) || isClicked}
            >
              Create
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
};
