import React from 'react';

import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  SecurityComponentPage,
  SecuritySystemPage
} from '@grupoboticario/backstage-plugin-maturity-security-frontend';

export const SecurityComponentContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={(e) => e.kind === 'Component'}>
      <SecurityComponentPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);

export const SecuritySystemContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={(e) => e.kind === 'System'}>
      <SecuritySystemPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
