import React from 'react';

import { useStyles } from './styles';
import { RenderModal } from '../../RenderModal';
import { EndpointsCard } from '../../EndpointsCard';
import type { ActionProps, EndpointsProps } from './types';

export const Endpoints = ({ entity, onClick }: EndpointsProps & ActionProps) => {
  const S = useStyles();
  return (
    <div className={S.contDetails}>
      <p className={S.titleProperty}>ENDPOINTS</p>
      <RenderModal titleLink="show URLs" onClick={onClick}>
        <EndpointsCard {...entity} />
      </RenderModal>
    </div>
  );
};
