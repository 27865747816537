import React from 'react';
import { useStyles } from './styles';
import type { ActionProps, ActionsButtonsProps } from './types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';
import DescriptionIcon from '@mui/icons-material/Description';
export const ActionsButtons = ({
  hrefTechDocs,
  viewUrl,
  techDocs,
  techDocsSystem,
  onClick
}: ActionsButtonsProps & ActionProps) => {
  const S = useStyles();
  const links = [
    { link: viewUrl, title: 'View Source', value: 'view-source', icon: GitHubIcon },
    { link: hrefTechDocs, title: 'View TechDocs', value: 'view-techdocs', icon: DescriptionIcon },
    { link: techDocs, title: 'View TechDocs', value: 'view techdocs', icon: DescriptionIcon },
    {
      link: techDocsSystem,
      title: 'View System TechDocs',
      value: 'view-system-techdocs',
      icon: DescriptionIcon
    }
  ].filter((link) => Boolean(link.link));

  if (links.filter(Boolean).length === 0) return null;

  return (
    <div className={S.contActions}>
      <Typography variant="body2" className={S.titleProperty}>
        ACTIONS
      </Typography>
      {links.map(({ link, icon: Icon, title, value }) => (
        <Button
          key={link}
          className={S.buttonAction}
          variant="contained"
          color="primary"
          component="a"
          href={link}
          target="_blank"
          onClick={() => onClick?.(`actions-${value}`)}
        >
          <Icon className={S.iconsButton} />
          {title}
        </Button>
      ))}
    </div>
  );
};
