import { Config } from '@backstage/config';
import {
  AnalyticsApi,
  analyticsApiRef,
  AnalyticsEvent,
  configApiRef,
  IdentityApi,
  identityApiRef
} from '@backstage/core-plugin-api';
import { createApiFactory } from '@backstage/frontend-plugin-api';
import { GoogleAnalyticsApiParse } from './googleAnalyticsParse';
import { GTAG } from './gtag';

export class GoogleAnalyticsApi implements AnalyticsApi {
  private readonly blocklist = ['navigate', 'click', 'search'];
  private readonly googleAnalyticsParse: GoogleAnalyticsApiParse;
  private readonly _gtag: GTAG;
  private constructor(
    readonly measurementId: string,
    readonly debugMode: boolean,
    readonly testMode: boolean,
    readonly sendPageViews: boolean,
    readonly identityApi: IdentityApi
  ) {
    this.googleAnalyticsParse = new GoogleAnalyticsApiParse();
    this._gtag = new GTAG(testMode, debugMode, sendPageViews);

    this._gtag.initialize(measurementId);

    this.setUserId();
  }

  static fromConfig(config: Config, identityApi: IdentityApi) {
    const measurementId = config.getString('app.analytics.ga4.measurementId');
    const debugMode = config.getOptionalBoolean('app.analytics.ga4.debug') ?? false;
    const testMode = config.getOptionalBoolean('app.analytics.ga4.testMode') ?? false;
    const sendPageViews = config.getOptionalBoolean('app.analytics.ga4.sendPageViews') ?? true;

    return new GoogleAnalyticsApi(measurementId, debugMode, testMode, sendPageViews, identityApi);
  }

  captureEvent(event: AnalyticsEvent): void {
    if (this.canSendEvent(event)) {
      const eventsParserd = this.googleAnalyticsParse.parseEvent(event);
      this._gtag.event(eventsParserd);
    }
  }

  private canSendEvent({ action }: AnalyticsEvent): boolean {
    return !this.blocklist.includes(action);
  }

  private setUserId() {
    this.getPrivateUserId();
  }

  private async getPrivateUserId(): Promise<string> {
    const { userEntityRef } = await this.identityApi.getBackstageIdentity();
    const userId = await this.encriptUserId(userEntityRef);
    this._gtag.set({ user_id: userId });
    return userId;
  }

  private encriptUserId(userId: string): Promise<string> {
    return window.crypto.subtle
      .digest('SHA-256', new TextEncoder().encode(userId))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
      });
  }
}
export const googleAnalyticsApi = createApiFactory({
  api: analyticsApiRef,
  deps: { configApi: configApiRef, identityApi: identityApiRef },
  factory: ({ configApi, identityApi }) => GoogleAnalyticsApi.fromConfig(configApi, identityApi)
});
