import React from 'react';

import { useStyles } from './styles';
import { RenderModal } from '../../RenderModal';
import { ChecksCard } from '../../ChecksCard';
import { ActionProps } from './types';

export const CatalogIntegrity = ({ onClick }: ActionProps) => {
  const S = useStyles();
  return (
    <div className={S.contDetails}>
      <p className={S.titleProperty}>CATALOG INTEGRITY</p>
      <RenderModal titleLink="show integrity" onClick={onClick}>
        <ChecksCard />
      </RenderModal>
    </div>
  );
};
