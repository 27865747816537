import { AnalyticsEvent } from '@backstage/core-plugin-api';
import { GoogleAnalyticsEvents } from './types';

export class GoogleAnalyticsApiParse {
  parseEvent(event: AnalyticsEvent): GoogleAnalyticsEvents {
    return this.parseCustomEvent(event);
  }

  private parseCustomEvent(event: Omit<AnalyticsEvent, 'value'>): GoogleAnalyticsEvents {
    return {
      event_name: event.action,
      ...event.attributes
    };
  }
}
