import React, { useEffect, useState } from 'react';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';
import { githubAuthApiRef, useApi, configApiRef } from '@backstage/core-plugin-api';

import { FieldValidation } from '@rjsf/utils';

import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import axios, { CancelTokenSource } from 'axios';

import { debounce } from 'lodash';
import { parseGithubRepoUrl } from './parseGithubRepoUrl';
import type { GithubRepo } from './types';
import { useAsync } from 'react-use';

export const GBTechRepositoryPicker = ({
  onChange,
  schema: { title = 'Repository', description = 'Github repository' },
  required,
  uiSchema,
  formData
}: FieldExtensionComponentProps<GithubRepo>) => {
  const uiOptions = 'ui:options';

  const config = useApi(configApiRef);

  const org = config.get('github.orgName') as string;
  const allowNewValue = uiSchema[uiOptions]?.allowNewValue as boolean | false;

  const githubAuth = useApi(githubAuthApiRef);
  const [githubToken, setGithubToken] = useState(String);
  const [options, setOptions] = React.useState<any[]>();
  const [value, _] = React.useState(formData || '');
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const cancelToken = React.useRef<CancelTokenSource>();

  useAsync(async () => {
    if (!githubToken) {
      const accessToken = await githubAuth.getAccessToken(['repo']);
      setGithubToken(accessToken);
    }
  }, [githubToken]);

  useEffect(() => {
    async function fetchRepos() {
      if (!inputValue || inputValue.trim().length <= 2) {
        return;
      }

      let queryString = org ? `org:${org} fork:true ${inputValue}` : inputValue;
      const parsedUrl = parseGithubRepoUrl(inputValue);
      if (parsedUrl) {
        queryString = `org:${parsedUrl.org} fork:true ${parsedUrl.repo}`;
      }

      setLoading(true);

      if (cancelToken?.current) {
        cancelToken.current.cancel();
      }

      cancelToken.current = axios.CancelToken.source();

      const graphqlQuery = {
        query: `query myOrgRepos($queryString: String!) {
                  search(query: $queryString, type: REPOSITORY, first: 10) {
                    repositoryCount
                      edges {
                        node {
                          ... on Repository {
                            name
                            url
                            nameWithOwner
                            owner {
                              login
                            }
                            defaultBranchRef{
                              name
                            }
                        }
                      }
                    }
                  }
              }`,
        variables: {
          queryString
        }
      };
      try {
        const response = await axios.post('https://api.github.com/graphql', graphqlQuery, {
          headers: {
            Authorization: `Bearer ${githubToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          cancelToken: cancelToken.current?.token
        });

        const repos = response.data.data.search.edges.map((e: any) => e.node);
        if (repos && repos.length > 0) {
          setOptions(repos);
        }

        if (parsedUrl) {
          const _nameWithOwner = `${parsedUrl.org}/${parsedUrl.repo}`;
          const _option = options?.find((repo) => repo.nameWithOwner === _nameWithOwner);

          if (_option) {
            onChange(_option);
            setInputValue(_nameWithOwner);
          }
        }

        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        setLoading(false);
        throw error;
      }
    }
    const fetchReposDebounced = debounce(fetchRepos, 500);
    fetchReposDebounced();

    if (!inputValue && formData) {
      onChange(undefined);
    }
  }, [formData, inputValue]);

  const onSelect = (__: any, selectedValue: any) => {
    let _value = selectedValue ?? '';
    let _nameWithOwner = selectedValue;

    const parsedUrl = parseGithubRepoUrl(inputValue);

    if (parsedUrl) {
      _nameWithOwner = `${parsedUrl.org}/${parsedUrl.repo}`;
    }

    if (typeof _nameWithOwner === 'string') {
      _value = options?.find((repo) => repo.nameWithOwner === _nameWithOwner);
    }

    onChange(_value);
    setInputValue(_value?.nameWithOwner ?? '');
  };

  return (
    <FormControl margin="normal" required={required}>
      <Autocomplete
        id="gbtech-repopicker-ac"
        value={value}
        onChange={onSelect}
        autoSelect
        inputValue={inputValue}
        data-testid="autocomplete"
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options || []}
        getOptionLabel={(option) => option.nameWithOwner ?? ''}
        freeSolo={!allowNewValue}
        renderInput={(params) => (
          <TextField
            {...params}
            id="gbtech-repopicker-input"
            value={inputValue}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </FormControl>
  );
};

export const GBTechRepositoryPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechRepositoryPicker,
    name: 'GBTechRepositoryPicker',
    validation: (data: any, fieldValidation: FieldValidation) => {
      if (!data) {
        fieldValidation.addError('Selecione um repositório válido');
      }
    }
  })
);
