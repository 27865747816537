import React, { useCallback } from 'react';

import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { alertApiRef, errorApiRef, useAnalytics, useApi } from '@backstage/core-plugin-api';

import { getAnnotationsValue } from '../../../../utils';
import { RenderTags } from '../RenderTags';
import { useStyles } from './styles';
import type { Props } from './types';
import { Description } from './contents/Description';
import { TypeEntity } from './contents/TypeEntity';
import { CatalogIntegrity } from './contents/CatalogIntegrity';
import { Endpoints } from './contents/Endpoints';
import { Lifecycle } from './contents/Lifecycle';
import { ArgoCdUrl } from './contents/ArgoCdUrl';
import { DevOpsTeam } from './contents/DevOpsTeam';
import { ContributorSquads } from './contents/ContributorSquads';
import { Status } from './contents/Status';
import { ActionsButtons } from './contents/ActionsButtons';
import { ActionsIcons } from './contents/ActionsIcons';
import { RenderLink } from '../RenderLink';

export const AboutCard = ({ hrefTechDocs, hrefEdit }: Props) => {
  const S = useStyles();
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);
  const analytics = useAnalytics();
  const viewUrl = getAnnotationsValue(entity, 'backstage.io/view-url');
  const techDocsRef = getAnnotationsValue(entity, 'backstage.io/techdocs-ref');
  const entityNamespace = entity.metadata.namespace;
  const techDocsSystem =
    techDocsRef && String(techDocsRef).includes('.gb.tech/docs')
      ? '/docs' + techDocsRef.split('/docs').pop()
      : '';
  const techDocs =
    techDocsRef && !String(techDocsRef).includes('.gb.tech')
      ? `/docs/${entityNamespace}/component/${entity.metadata.name}`
      : '';

  const systemLocation = entity.metadata.annotations?.[
    'backstage.io/managed-by-location'
  ]?.startsWith('entities-api')
    ? entity.metadata.annotations?.['backstage.io/managed-by-location'].split('/')[4]
    : '';
  const editSystem = systemLocation ? `/system-catalog/edit/${systemLocation}` : '';
  const editComponent = entity.metadata.annotations?.['backstage.io/edit-url'];

  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      alertApi.post({
        message: 'Refresh scheduled',
        severity: 'info',
        display: 'transient'
      });
    } catch (e) {
      errorApi.post(e);
    }
  }, [catalogApi, alertApi, errorApi, entity]);

  const handleOnClick = (value: string) => {
    analytics.captureEvent('interaction_overview', '', {
      attributes: {
        cd_interaction: value
      }
    });
  };
  const removeNamespaceFromValue = (value: string) => value.replace(`${entityNamespace}/`, '');
  return (
    <div className={S.contAbout}>
      <div>
        <h2 className={S.title}>About</h2>
        <div className={S.propertiesDetails}>
          <div className={S.propertiesDetailsLine}>
            {entity.metadata.description && (
              <Description description={entity.metadata.description} />
            )}
            {entity.spec?.type && <TypeEntity type={String(entity.spec.type)} />}
            {entity.kind === 'Component' && (
              <CatalogIntegrity onClick={() => handleOnClick('about:click:catalog-integrity')} />
            )}
            {entity.kind === 'Component' && entity.metadata.links && (
              <Endpoints entity={entity} onClick={() => handleOnClick('about:click:endpoints')} />
            )}
            {entity.spec?.lifecycle && <Lifecycle lifecycle={String(entity.spec.lifecycle)} />}
            {entity.spec?.argocdUrl && <ArgoCdUrl url={String(entity.spec.argocdUrl)} />}
            {entity.spec?.devOpsTeam && (
              <DevOpsTeam
                team={String(entity.spec.devOpsTeam)}
                namespace={String(entityNamespace)}
              />
            )}
          </div>
          <div className={S.propertiesDetailsLine}>
            <RenderTags title={'TAGS'} />
            {entity.spec?.owner && (
              <RenderLink
                resultProperty={String(entity.spec.owner)}
                title={'OWNER'}
                href={`/catalog/${entityNamespace}/group/${removeNamespaceFromValue(String(entity.spec.owner))}`}
              />
            )}
            {entity.spec?.squads && (
              <ContributorSquads
                squads={entity.spec.squads as string[]}
                namespace={String(entityNamespace)}
                onClick={() => handleOnClick('about:click:contributor-squads')}
              />
            )}
            {entity.spec?.domain && (
              <RenderLink
                resultProperty={String(entity.spec.domain)}
                title={'DOMAIN'}
                href={`/catalog/default/domain/${entity.spec.domain}`}
                onClick={() => handleOnClick('about:click:domain')}
              />
            )}
            {entity.spec?.system && (
              <RenderLink
                resultProperty={String(entity.spec.system)}
                title={'SYSTEM'}
                href={`/catalog/${entityNamespace}/system/${removeNamespaceFromValue(String(entity.spec.system))}`}
                onClick={() => handleOnClick('about:click:system')}
              />
            )}
            <RenderTags title={'RUNNERS'} />
            {'enabled' in (entity.spec || {}) && (
              <Status enabled={Boolean(entity.spec?.enabled)} kind={entity.kind} />
            )}
          </div>
        </div>
        <ActionsButtons
          hrefTechDocs={hrefTechDocs}
          viewUrl={viewUrl}
          techDocs={techDocs}
          techDocsSystem={techDocsSystem}
          onClick={(value: string | undefined) => handleOnClick(`about:click:${value}`)}
        />
      </div>
      <ActionsIcons
        refreshEntity={refreshEntity}
        hrefEdit={hrefEdit}
        editSystem={editSystem}
        editComponent={editComponent}
      />
    </div>
  );
};
