const shouldSkipKey = (key: string) => {
  const skipKeys = ['token', 'environmentSet', 'githubUser', 'component', 'repo'];
  return skipKeys.includes(key);
};

const handleObjectEntries = (analytics: any, key: string, value: any) => {
  Object.entries(value).forEach(([subKey, subValue]) => {
    if (['repo', 'component'].includes(subKey)) return;

    const details = Array.isArray(subValue) && subValue.length > 0 ? subValue.join(',') : subValue;

    if (details) {
      captureEvent(analytics, `${key}.${subKey}:submitform:${details}`);
    }
  });
};

const handleArrayValues = (analytics: any, key: string, value: any[]) => {
  value.forEach((item, index) => {
    if (typeof item === 'object' && item !== null) {
      Object.entries(item).forEach(([subKey, subValue]) => {
        if (subValue) {
          captureEvent(analytics, `${key}[${index}].${subKey}:submitform:${subValue}`);
        }
      });
    } else {
      captureEvent(analytics, `${key}[${index}]:submitform:${item}`);
    }
  });
};

export const captureEvent = (analytics: any, details: string) => {
  analytics.captureEvent('interaction_creation_summary', 'create', {
    attributes: {
      cd_interaction_detail: details.toLowerCase()
    }
  });
};

export const handleFieldEvents = (analytics: any, formData: any) => {
  const lastPathSegment = window.location.pathname.split('/').pop() || '';
  captureEvent(analytics, `template:submit:${lastPathSegment}`);

  Object.entries(formData).forEach(([key, value]) => {
    if (shouldSkipKey(key)) return;

    if (key === 'appServices' && typeof value === 'object' && value !== null) {
      const enabledPartials = Object.entries(value)
        .filter(([_, service]) => service?.enable)
        .map(([_, service]) => service?.partial)
        .join('|');
      captureEvent(analytics, `appservices:submitform:${enabledPartials}`);
    } else if (key === 'services' && Array.isArray(value) && value.length > 0) {
      const serviceValues = value.map((serviceItem) => serviceItem?.service).join('|');
      captureEvent(analytics, `services:submitform:${serviceValues}`);
    } else if (key === 'catalogInfo' && typeof value === 'object' && value !== null) {
      handleObjectEntries(analytics, 'cataloginfo', value);
    } else if (Array.isArray(value) && value.length > 0) {
      handleArrayValues(analytics, key, value);
    } else if (typeof value === 'object' && value !== null) {
      handleObjectEntries(analytics, key, value);
    } else if (value) {
      captureEvent(analytics, `${key}:submitform:${value}`);
    }
  });
};
