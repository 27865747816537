import {
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { RepositoryBasicDto } from '../gateways/repositories/generic-repository-service.interface';
import { RepositoryResponse } from '../gateways/repositories/repository-reponse.type';
import { GenericUsecaseFactory } from '../gateways/usecases/generic-usecase-factory';
import { GenericUsecase } from '../gateways/usecases/generic-usecase.interface';
import { AxiosHttp } from './axios-http.adapter';

export interface GenericCatalogAPI {
  createAdapter(): Promise<AxiosHttp>;
  execute<TDto extends RepositoryBasicDto>(
    usecaseFactory: GenericUsecaseFactory<TDto>,
    usecaseName: string,
    ...args: any
  ): Promise<any>;
}

export type Options = {
  discoveryApi: DiscoveryApi;
  proxyPath?: string;
  identityApi: IdentityApi;
};

export class GenericCatalogAPIClient implements GenericCatalogAPI {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private createAxiosAdapter(
    url: string,
    token: string | undefined,
    userMail: string | undefined
  ): AxiosHttp {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${token}`,
      'user-mail': userMail,
    };
    const axiosHttp = new AxiosHttp(url, headers);
    return axiosHttp;
  }

  private async getBaseUrl() {
    return await this.discoveryApi.getBaseUrl('proxy');
  }

  public async createAdapter(): Promise<AxiosHttp> {
    const baseUrl = await this.getBaseUrl();
    const token = (await this.identityApi.getCredentials()).token;
    const { email } = await this.identityApi.getProfileInfo();
    this.identityApi.getProfileInfo();

    const axios = this.createAxiosAdapter(`${baseUrl}/entities-api`, token, email);
    return axios;
  }

  private async createUseCaseApi<TDto extends RepositoryBasicDto>(
    usecaseFactory: GenericUsecaseFactory<TDto>,
    usecaseName: string,
  ): Promise<GenericUsecase<TDto>> {
    return usecaseFactory.create(usecaseName, this);
  }

  private async executeApi<TDto extends RepositoryBasicDto>(
    usecaseFactory: GenericUsecaseFactory<TDto>,
    usecaseName: string,
    ...args: any
  ): Promise<RepositoryResponse<TDto | TDto[] | boolean>> {
    const useCase = await this.createUseCaseApi(usecaseFactory, usecaseName);
    const res = await useCase.execute(...args);

    if (res.ok) {
      return res;
    }
    throw res.error;
  }

  async execute<TDto extends RepositoryBasicDto>(
    usecaseFactory: GenericUsecaseFactory<TDto>,
    usecaseName: string,
    ...args: any
  ): Promise<RepositoryResponse<TDto | TDto[] | boolean>> {
    return this.executeApi(usecaseFactory, usecaseName, ...args);
  }
}
