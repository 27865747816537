import { WindowAttributes } from '../../types';
import { GoogleAnalyticsEvents } from './types';

export class GTAG {
  private initialized: boolean = false;
  constructor(
    readonly testMode: boolean,
    readonly debugMode: boolean,
    readonly sendPageViews: boolean
  ) {
    this.testMode = testMode;
    this.debugMode = debugMode;
    this.sendPageViews = sendPageViews;
  }

  initialize(measurementId: string) {
    if (this.testMode) {
      this.createScript(measurementId);
    }
    if (!this.initialized) {
      window.dataLayer = window.dataLayer || [];
      this._gtag({ js: new Date() });
      this._gtag({
        config: measurementId,
        send_page_view: this.sendPageViews,
        debug_mode: this.debugMode
      });
      this.initialized = true;
    }
  }

  event(eventAttributes: GoogleAnalyticsEvents) {
    this._gtag({ event: 'analytics-event', ...eventAttributes });
  }
  set(attributes: GoogleAnalyticsEvents) {
    this._gtag({ ...attributes });
  }

  private _gtag(...args: WindowAttributes[]) {
    if (typeof window !== 'undefined' && typeof window.gtag === 'undefined') {
      this.initializeGtagTracking();
    }
    window.gtag(...args);
  }
  private createScript(measurementId: string) {
    if (!this.initialized) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
      script.async = true;
      document.body.appendChild(script);
      this.initializeGtagTracking();
      this.initialized = true;
    }
  }
  private initializeGtagTracking() {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag(event: any) {
      window.dataLayer.push(event);
    };
  }
}
