import React, { useEffect, useState } from 'react';

import { useAnalytics, useApi } from '@backstage/core-plugin-api';
import { identityApiRef, discoveryApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { InfoCard } from '@backstage/core-components';
import { configApiRef } from '@backstage/core-plugin-api';

import validator from '@rjsf/validator-ajv8';
import { RegistryFieldsType, UiSchema } from '@rjsf/utils';
import { Theme as MuiTheme } from '@rjsf/material-ui';
import { withTheme } from '@rjsf/core';

import { parse } from 'yaml';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import FormHelperText from '@mui/material/FormHelperText';

import { styled, keyframes } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { GBList } from './fields/GBTechList/GbList';
import { AutoServiceEntity } from '../types/AutoServiceEntity';
import { useStyles } from './styles';
import { AutoServiceApiClient } from '../api';

const Form = withTheme(MuiTheme);

const allAlquimiaGenericAutoServices = ['argoApp', 'argoInfra', '*'];

const fields: RegistryFieldsType = { GbList: GBList };

export const AutoServiceEntityPageComponent = () => {
  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const configApi = useApi(configApiRef);
  const analytics = useAnalytics();
  const org = configApi.get('github.orgName');
  const actionRepositoryLink = `https://github.com/${org}/plat-eng-alquimia-auto-service-actions/actions`;

  const [availableAutoServices, setAvailableAutoServices] = useState<AutoServiceEntity[]>([]);
  const [selectedAutoService, setSelectedAutoService] = useState<AutoServiceEntity>();

  const [formContext, setFormContext] = useState<any>({});

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [isAutoServiceLoading, setIsAutoServiceLoading] = useState(false);
  const [isAutoServiceExecuting, setIsAutoServiceExecuting] = useState(false);
  const [error, setError] = useState<string>();
  const entity = useEntity().entity;
  const S = useStyles();
  const api = new AutoServiceApiClient({ discoveryApi, identityApi });

  const submit = async (context: any) => {
    setIsAutoServiceExecuting(true);
    try {
      context.formData['component-manifest'] = entity;
      await api.callService(context.formData);
      setOpenSnackBar(true);
    } catch (caughtError: any) {
      setError(caughtError.message);
      setOpenErrorSnackBar(true);
    }
    setIsAutoServiceExecuting(false);
  };

  const getAvailableAutoServices = async () => {
    setIsAutoServiceLoading(true);
    const autoServices = await catalogApi.getEntities({
      filter: {
        kind: ['Autoservice']
      }
    });
    const genericAutoService = autoServices.items.filter(
      (autoService: any) => autoService.spec.type === '*'
    ) as AutoServiceEntity[];

    if (!entity.spec?.environmentSet) {
      autoServices.items = autoServices.items.filter(
        (autoService) => autoService.spec?.requireEnvset === false
      );
    }

    if (entity?.metadata.annotations?.['argocd/has-apps'] === 'false') {
      setAvailableAutoServices(genericAutoService);
      setIsAutoServiceLoading(false);
      return;
    }
    const argoEnvs = await api.getAmbientes(entity);
    if (!argoEnvs.data.items) {
      setAvailableAutoServices(genericAutoService);
      setIsAutoServiceLoading(false);
      return;
    }
    const argoInfraEnvs = argoEnvs.data.items.filter((env: any) =>
      env.metadata.name.endsWith('infra')
    );
    const infraYamlList = argoInfraEnvs.map((env: any) => parse(env.spec.source.helm.values));
    const keys = infraYamlList.map((infraYaml: any) => Object.keys(infraYaml.infra)).flat();
    const availableAutoServicesList = autoServices.items.filter(
      (autoService: any) =>
        keys.includes(autoService.spec.infraYamlResourceName) ||
        allAlquimiaGenericAutoServices.includes(autoService.spec.type as string)
    ) as AutoServiceEntity[];
    setAvailableAutoServices(availableAutoServicesList);
    setIsAutoServiceLoading(false);
  };

  const handleAutoServiceChange = async (event: SelectChangeEvent<unknown>) => {
    formContext.formData = {};
    formContext.formData = {
      ...formContext.formData,
      autoservice: event.target.value
    };
    const taskSelected = event.target.value as AutoServiceEntity;
    setSelectedAutoService(taskSelected);
    analytics.captureEvent('interaction_overview', '', {
      attributes: {
        cd_interaction_detail: `auto-service:choose:${taskSelected.metadata?.displayName}`
      }
    });
  };
  const onChange = (e: any) => setFormContext(e);

  useEffect(() => {
    getAvailableAutoServices();
  }, []);

  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

  const Loading = styled(AutorenewIcon)({
    animation: `${spin} 1s infinite ease`
  });

  return (
    <InfoCard
      title="Auto service"
      subheader="Execute infrastructure automation tasks"
      deepLink={{
        link: 'https://alquimia.gb.tech/docs/default/component/alquimia/auto-service/',
        title: 'Documentation'
      }}
    >
      <FormControl className={S.AutomationSelect} required variant="outlined">
        <InputLabel id="autoService">Select automation task</InputLabel>
        <Select
          labelId="autoService"
          id="autoService"
          name="autoService"
          label="Select automation task"
          required
          fullWidth
          onChange={handleAutoServiceChange}
          disabled={isAutoServiceLoading}
          IconComponent={isAutoServiceLoading ? Loading : ArrowDropDownIcon}
          variant="outlined"
        >
          {availableAutoServices?.map((autoServiceEntity) => (
            <MenuItem
              className={S.MenuItem}
              key={autoServiceEntity.metadata.name}
              value={autoServiceEntity as any}
            >
              {`${autoServiceEntity.metadata.displayName}`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          Algumas automações são exclusivas para componentes com EnvironmentSet e podem não aparecer
        </FormHelperText>
      </FormControl>
      {selectedAutoService && (
        <Card className={S.CardAutomation} variant="outlined">
          <CardContent>
            <h2 className={S.Title}>{`${selectedAutoService?.metadata.displayName}`}</h2>
            {selectedAutoService?.metadata.description && (
              <ReactMarkdown
                children={selectedAutoService?.metadata.description}
                remarkPlugins={[remarkGfm]}
              />
            )}
            <Form
              disabled={isAutoServiceExecuting}
              className={S.Form}
              schema={selectedAutoService.spec.schema}
              validator={validator}
              uiSchema={selectedAutoService.spec.schema['ui:schema'] as UiSchema}
              fields={fields}
              onSubmit={submit}
              formContext={formContext}
              formData={formContext.formData}
              onChange={onChange}
            />
          </CardContent>
        </Card>
      )}
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={null}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          action={
            <Link target="_blank" href={actionRepositoryLink}>
              Clique aqui para acompanhar o progresso.
            </Link>
          }
          onClose={() => setOpenSnackBar(false)}
          severity="success"
        >
          Action iniciada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openErrorSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={null}
        onClose={() => setOpenErrorSnackBar(false)}
      >
        <Alert
          onClose={() => {
            setOpenErrorSnackBar(false);
            setError('');
          }}
          severity="error"
        >
          {error}
        </Alert>
      </Snackbar>
    </InfoCard>
  );
};

export const AutoServiceEntityPage = <AutoServiceEntityPageComponent />;
