import React from 'react';

import LaunchIcon from '@mui/icons-material/Launch';

import Link from '@mui/material/Link';

import { useStyles } from './styles';
import type { RenderLinkProps } from './types';
import { ActionProps } from '../AboutCard/contents/types';

export const RenderLink = ({
  title,
  resultProperty,
  href,
  onClick
}: RenderLinkProps & ActionProps) => {
  const S = useStyles();

  return (
    <div className={S.contDetails}>
      <p className={S.titleProperty}>{title}</p>
      <Link href={href} target="_blank" className={S.resultProperty} onClick={() => onClick?.()}>
        {resultProperty}
        <LaunchIcon className={S.icon} />
      </Link>
    </div>
  );
};
