import React from 'react';

import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  MaturitySystemPage
} from '@grupoboticario/backstage-plugin-dev-journey-maturity-frontend';

export const DevJourneyMaturitySystemContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={(e) => e.kind === 'System'}>
      <MaturitySystemPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
